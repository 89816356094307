<template>
  <edit-template @confirm="submitForm('formRule')" @cancel="handleBack" :cancelRemind="false" v-loading="loading">
    <div class="pt-5"></div>
    <el-form :model="formData" :rules="rules" ref="formRule" label-width="100rem">
      <el-form-item label="视频分类" prop="category_name">
        <el-input v-model="formData.category_name" maxlength="20" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item :label="index ? '' : '类别名称'" :prop="`type_list.${index}.name`" :rules="dynamicValidate" v-for="(domain, index) in formData.type_list" :key="domain.key">
        <el-input class="mr-2" v-model="domain.name" maxlength="20" placeholder="请输入"></el-input>
        <el-button icon="el-icon-delete" circle="" v-if="formData.type_list.length !== 1" @click.prevent="removeDomain(domain)"></el-button>
        <el-button icon="el-icon-plus" circle="" v-if="formData.type_list.length !== maxlength && index === formData.type_list.length - 1" @click="addDomain"></el-button>
      </el-form-item>
    </el-form>
  </edit-template>
</template>

<script>
import { formDataBackMixin } from '@/utils/mixins'
import { getApi2, postApi2 } from "@/api"
export default {
	_config:{"route":[{"path":"edit","meta":{"title":"编辑"}}, {"path":"add","meta":{"title":"新增"}}]},
  mixins: [formDataBackMixin],
  data() {
    const validateDomains = (rule, value, callback) => {
      if (!value) {
        callback()
      } else {
        const list = this.formData.type_list.map(i => i.name)
        let result = list.filter(i => i === value)
        if(result.length > 1) callback('类别名称有重复，请更正后提交')
        callback()
      }
    };
    return {
      id: '',
      maxlength: 10,
      loading: false,
      formData: {
        category_name: '',
        type_list: []
      },
      rules: {
        category_name: [{ required: true, message: '请输入频类型', trigger: 'blur' }],
      },
      dynamicValidate: { required: false, validator: validateDomains, trigger: 'blur' },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const { query: { id }, path } = this.$route
      if(path.includes('add')) {
        return this.formData.type_list.push( { name: '', key: Date.now() })
      }
      if(!id) {
        this.$message.warning('缺少id参数')
        return this.formData.type_list.push( { name: '', key: Date.now() })
      }
      this.id = id
      this.loading = true
      getApi2(`/video/get-category?id=${id}`).then(res => {
        const { category_name, type_list } = res
        const arr = type_list.length ? type_list.map((i, j) => ({ ...i, key: Date.now() + j })) : [{ name: '', key: Date.now() }]
        this.formData = {category_name, type_list: arr}
      }).finally(() => this.loading = false)
    },
    addDomain() {
      if(this.maxlength === this.formData.type_list.length) {
        return this.$message.warning(`最多只能添加${this.maxlength}条`)
      }
      this.formData.type_list.push({ name: '', key: Date.now() })
    },
    removeDomain(item) {
      const index = this.formData.type_list.indexOf(item)
      if (!~index) return
      this.formData.type_list.splice(index, 1)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { category_name, type_list } = this.formData
          let list = []
          const data = { category_name }
          if(this.id) {
            data.id = this.id
            list = type_list.filter(i => i.name).map(i => {
              const obj = { name: i.name }
              if(i.id) {
                obj.id = i.id
              }
              return obj
            })
          } else {
            list = type_list.filter(i => i.name).map(i => i.name)
          }
          data.type_list = list
          const api = `/video/${this.id ? 'edit' : 'add'}-category`
          this.loading = true
          postApi2(api, data).then(() => {
            this.$message.success(`${this.id ? '编辑' : '添加'}成功`)
            this.$router.back()
          }).finally(() => this.loading = false)
        }
      })
    },
    handleBack() {
      this.formBack(this.formData, ['type_list'])
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input{
  width: 320rem !important;
}
</style>
